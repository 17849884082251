import { AuthTokens, ErrorResponse, SuccessLoginResponse } from '~types';

const apiURL = process.env.API_BASEURL || process.env.NEXT_PUBLIC_API_BASEURL;
const defaultFetch = <R = any>(endpoint: string, options: RequestInit = {}) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'x-source-app': typeof window !== 'undefined' ? 'website-browser' : 'website-server',
  };
  return new Promise<R>((resolve, reject) => {
    fetch(`${apiURL}${endpoint}`, {
      ...options,
      headers: { ...defaultHeaders, ...(options?.headers ? options.headers : {}) },
    })
      .then(async (res) => {
        if ([200, 201, 202, 203, 204].includes(res.status)) {
          return resolve((await res.json()) as R);
        } else {
          reject({
            error: true,
            status: res.status,
            statusText: res.statusText,
            data: await res.json(),
          });
        }
      })
      .catch(reject);
  });
};

export const handleApiError =
  (notifyErrors = true, notifyValidationErrors = true) =>
  (error: { status: number; statusText: string; data: ErrorResponse }): never => {
    let message = 'Sin respuesta del servidor, verifique su conexión a internet.';
    if (error?.status) {
      message = `<p>${error.status || error?.data?.statusCode}: ${error?.data?.error}.</p>`;
      if (error.data.validationErrors && notifyValidationErrors) {
        const validationErrors: Record<string, string> = {};
        error.data.validationErrors?.map(
          (error: { path?: string; field: string; message: string }) => {
            validationErrors[error.path || error.field] = error.message;
            return error;
          },
        );
        message += `<ul>${Object.keys(validationErrors)
          .map((key) => `<li>${validationErrors[key]}</li>`)
          .join('')}</ul>`;
      }

      if (typeof window !== 'undefined' && notifyErrors) {
        import('~components/ui/Notification').then((mod) => {
          const Notification = mod.default;
          Notification.error({
            title: error.data.message,
            message,
            htmlMessage: true,
            placement: 'top',
          });
        });
      }
    }

    if (error?.data) {
      throw error.data;
    }
    throw {
      error: true,
      status: error?.status || error?.data?.statusCode || 500,
      message: error?.statusText || 'Sin respuesta del servidor, verifique su conexión a internet.',
    };
  };

export function getFromAPI<R = any>(
  endpoint: string,
  options?: RequestInit | undefined,
): Promise<R> {
  return defaultFetch(endpoint, options).catch(handleApiError());
}

export function postToAPI<R = any>(
  endpoint: string,
  data: any = {},
  opt?: RequestInit & { notifyErrors?: boolean; notifyValidationErrors?: boolean },
): Promise<R> {
  const { notifyErrors, notifyValidationErrors, ...options } = opt || {};
  return defaultFetch<R>(endpoint, {
    method: 'POST',
    ...options,
    body: data ? JSON.stringify(data) : null,
  }).catch(handleApiError(notifyErrors, notifyValidationErrors));
}

export function putToAPI<R = any>(
  endpoint: string,
  data: any = {},
  opt?: RequestInit & { notifyErrors?: boolean; notifyValidationErrors?: boolean },
): Promise<R> {
  const { notifyErrors, notifyValidationErrors, ...options } = opt || {};
  return defaultFetch<R>(endpoint, {
    method: 'PUT',
    ...options,
    body: data ? JSON.stringify(data) : null,
  }).catch(handleApiError(notifyErrors, notifyValidationErrors));
}

export function patchToAPI<R = any>(
  endpoint: string,
  data: any = {},
  opt?: RequestInit & { notifyErrors?: boolean; notifyValidationErrors?: boolean },
): Promise<R> {
  const { notifyErrors, notifyValidationErrors, ...options } = opt || {};
  return defaultFetch<R>(endpoint, {
    method: 'PATCH',
    ...options,
    body: data ? JSON.stringify(data) : null,
  }).catch(handleApiError(notifyErrors, notifyValidationErrors));
}

export function deleteFromAPI<R = any>(
  endpoint: string,
  opt?: RequestInit & { notifyErrors?: boolean; notifyValidationErrors?: boolean },
): Promise<R> {
  const { notifyErrors, notifyValidationErrors, ...options } = opt || {};
  return defaultFetch<R>(endpoint, { ...options, method: 'DELETE' }).catch(
    handleApiError(notifyErrors, notifyValidationErrors),
  );
}

export function multipleReq<R = any>(endpoints: { [key: string]: string }): Promise<R> {
  return postToAPI<R>('/multiple-request', endpoints);
}

export const getTokens = (data: SuccessLoginResponse): AuthTokens => {
  return {
    token: data.token,
    accessTokenExpiresIn: data.accessTokenExpiresIn,
    refreshTokenExpiresIn: data.refreshTokenExpiresIn,
    refreshToken: data.refreshToken,
    firebaseToken: `${data.firebaseToken}`,
  };
};
